export const customConfig = {
  brandName: "",
  login: {
    showHelperText: false,
  },
  authExternalLogin: false,
  googleCaptcha: false,
  applicationsCredentialInfoLink: false,
  onboardingUpdateAccountLink: false,
  onBoardingCustomPath: `updateAccountType/`,
  offBoardingCustomPath: `?token=`,
  convertTimestampSecondsToMilliseconds: false,
  fiscalIdKeyName: "cuit",
  showAccountLabel: false,
  paramNameSubsidiaryId: "locationId",
  bankAccountIdentifierKeyName: "cbu",
  bankAccountAlternativeIdentifierKeyName: "alias",
  bankAccountNumberLabel: "CBU",
  bankAccountNumberAlternativeLabel: "Alias",
  bankAccountNumberDataType: "number",
  bankAccountNumberAlternativeDataType: "text",
  bankAccountIdentifierKeyLength: 22,
  automaticCashout: {
    isEnabled: false,
  },
  disabledCreateNewAdminUser: false,
  uniqueCodeEnable: false,
  editDocumentUserForm: false,
  showCustomIconProfile: false,
  showReader: false,
  showLocal: false,
  termsAndConditions: false,
  showDocumentType: true,
  showPhoneNumber: true,
  activatePoket: false,
  showLocations: false,
  dropdownMenuItems: [],
  firstLoginBuyReaderModalEnabled: true,
  readerImage: null,
  showCurrencyFilter: false,
  showCurrencyInBestSellersWidget: false,
  showAccountType: true,
  showMerchantArea: true,
  showBankAccountSelectForm: false,
  showQRSalesAsPCT: false,
  showAccountNameInMerchantArea: false,
  showFiscalIdInMerchantArea: true,
  customCurrencyCode: "032",
  showCustomLogin: false,
  loginChannel: "web",
  showNavHelpPhone: false,
  maxAmountLimit: 8,
  helpModal: {
    frequentQuestionsURL: null,
    contactFormURL: null,
    contactEmail: null,
  },
  stats: {
    sales: {
      charts: {
        table: {
          showTips: false,
          showDiscount: false,
          showTax: false,
        },
      },
    },
    statsEnabled: ["products", "staff", "customers"],
  },
  deposits: {
    showDetail: false,
    showTaxMessage: false,
    taxMessage: "false",
    options: {
      formats: [
        { value: 'xls', label: 'Excel' }
      ],
    },
  },
  catalog: {
    products: {
      imageMBMaxSize: 5,
    },
    fieldsPanel: [
      {
        type: "text",
        name: "productName",
      },
      {
        type: "amount",
        name: "productPrice",
      },
      {
        type: "textarea",
        name: "productDescription",
      },
    ],
  },
  filters: {
    amount: {
      initialMinMultiplier: 0,
      initialMaxMultiplier: 1,
      min: 0,
      max: 100000,
      step: 1,
    },
    paymentChannel: {
      terminalPaymentOptions: ["ECOMMERCE", "MPOS", "QR", "SMARTPOS", "STORE"],
    },
  },
  sales: {
    showReversedFilter: false,
    operationId: true,
    tabs: undefined,
    showReportWidget: true,
    showFilterSubsidiariesWidget: false,
    showReferenceNumberFilter: false,
    showCustomCurrenciesInFilter: false,
    transactionReceipt: {
      showTip: false,
      salesTerminalPayment: false,
      saleInformation: [
        {
          type: "date",
          icon: "mdi mdi-calendar",
          method: (sale) => {
            let saleDateTimes = {};

            if (sale.dateTimeTimestamp) {
              // already timezoned
              saleDateTimes["dateTimeTimestamp"] = sale.dateTimeTimestamp;
            }
            if (sale.dateTimeTimestampUTC) {
              // NOT timezoned
              saleDateTimes["dateTimeTimestampUTC"] = sale.dateTimeTimestampUTC;
            }
            if (sale.localTimezone) {
              // timezone
              saleDateTimes["localTimezone"] = sale.localTimezone;
            }
            if (sale.dateTime) {
              // string formatted
              saleDateTimes["dateTime"] = sale.dateTime;
            }

            return saleDateTimes;
          },
        },
        {
          icon: "mdi mdi-account",
          method: (sale) => sale.userName,
        },
        {
          type: "map",
          icon: "mdi mdi-calendar",
          latitude: (sale) => sale.latitude,
          longitude: (sale) => sale.longitude,
        },
      ],
      recharge: [
        {
          icon: (sale) => sale.latitude,
          method: (sale) =>
            sale.recharge.placeholder + " " + sale.recharge.destinationNumber,
        },
        {
          type: "currency",
          icon: "mdi mdi-cellphone-android",
          method: (sale) => sale.recharge.commission,
        },
      ],
      items: {
        items: (sale) => sale.items,
        totalAmount: (sale) => sale.totalAmount,
        currency: (sale) => sale.currency,
      },
      showSummary: (sale) =>
        (sale.payment && sale.payment.method === "card") ||
        (sale.payment && sale.payment.method === "") ||
        null,
      summary: [
        {
          label: "Titular de tarjeta",
          method: (sale) =>
            (sale.payment && sale.payment.cardHolderName) || null,
        },
        {
          label: "Terminal ID",
          method: (sale) => sale.terminalID,
        },
        {
          label: "N° de referencia",
          method: (sale) => sale.referenceNumber,
        },
        {
          label: "Nº de autorización",
          method: (sale) => sale.authorizationCode,
        },
      ],
      contactData: [
        {
          label: "name",
          method: (sale) =>
            sale.buyer?.firstName && sale.buyer?.lastName
              ? `${sale.buyer.firstName} ${sale.buyer?.lastName}`
              : sale.buyerName,
        },
        {
          label: "email",
          method: (sale) => sale.buyer?.email,
        },
        {
          label: "phone",
          method: (sale) => sale.buyer?.phone,
        },
      ],
    },
    options: {
      type: [
        { value: "", label: "Todos" },
        { value: "SALE", label: "Venta" },
        { value: "ANNULMENT", label: "Anulación" },
      ],
      status: [],
      formats: [{ value: "xls", label: "Excel" }],
    },
  },
  closeBatch: {
    tabs: undefined,
  },
  merchant: {
    showSelectsAddres: true,

    tabs: ["users"],
    subsidiaries: {
      fieldsPanel: [
        {
          type: "text",
          name: "street",
        },
        {
          type: "text",
          name: "floor",
        },
        {
          type: "text",
          name: "reference",
        },
      ],
    },
  },
  currencies: [
    { value: "032", label: "Pesos", code: "ARS", symbol: "$", digits: "2" },
  ],
  ecommerceStore: {
    accountCheck: false,
    button: false,
    phoneLength: 14,
    phoneRegexValidation: /[^0-9]/g,
    documentRegexValidation: /[^0-9]/g,
    documentRequired: 1
  },
  products: {
    imageMBMaxSize: 5,
    maxStockQuantityInput: 999999,
  },
  logout: {
    redirectTo: "/",
  },
  tenantMaxLength: {
    phoneLength: undefined,
    documentLength: undefined,
  },
  paymentLink: {
    type: "paymentLink",
    biometricValidation: false,
    bankAccountValidation: false,
    hideWhenDisabled: true,
    createAnotherOptionEnabled: false,
  },
  createOrChangePasswordMinLengthValue: 8,
  createOrChangePasswordReqsField: (minLengthValue) => {
    return [
      {
        test: (value) => value && value.length >= minLengthValue,
        label: "resetPassword.form.userPassword.requirements.minLength",
      },
      {
        test: (value) => value && value.match(/[a-z]/i),
        label: "resetPassword.form.userPassword.requirements.anyLetter",
      },
      {
        test: (value) => value && value.match(/\d/i),
        label: "resetPassword.form.userPassword.requirements.anyNumber",
      },
    ];
  },
  createOrChangePasswordReqsSubmit: [
    {
      field: "password",
      test: (val) =>
        val && val.length >= 8 && val.match(/[a-z]/i) && val.match(/\d/i),
    },
  ],
};
