import React from 'react'
import Highcharts from 'react-highcharts'

import { set } from 'lodash'
import i18n from 'utils/i18n'
import { getChartsColors } from 'utils'

Highcharts.Highcharts.setOptions({
  "lang": {
    "months": i18n('month.lang.january'),
    "shortMonths": i18n('month.lang.jan'),
    "weekdays": i18n('week.daysOfWeek')
  }
})

export const tooltipFormatter = function(chart) {
  const formatter = chart.chart.userOptions.customTooltip
  const point = this.points ? this.points[0].point : this.point
  if (formatter) {
    return formatter({
      x: this.x || point.name,
      y: this.y,
      percentage: this.percentage,
      total: this.total,
      index: point.x,
      item: point.item,
      items: this.points ? this.points.map(p => p.point.item) : point.item
    })
  }
}

export const buildTooltip = ({ title, value, comparison }) => `
  <div class="chart-tooltip">
    <div class="chart-tooltip-title">${title}</div>
    <div class="chart-tooltip-value">${value}</div>
    ${ comparison ? `<div class="chart-tooltip-comparison">${comparison}</div>` : ''}
  </div>
`

export const defaultTooltip = ({ x, y }) => (
  buildTooltip({ title: x, value: y })
)

export const getDefaultConfig = () => ({
  "chart.height": 300,
  "chart.style.fontFamily": "Ubuntu",
  "credits.enabled": false,
  "title.text": null,
  "subtitle.text": null,
  "yAxis.title.text": null,
  "colors": getChartsColors(),
  "plotOptions.lineWidth": 1,
  "plotOptions.series.animation.duration": 500,
  "plotOptions.series.pointPadding": 0.1,
  "plotOptions.series.groupPadding": 0.05,
  "plotOptions.series.marker": {
    "symbol": "circle",
    "radius": 6,
    "lineColor": null,
    "states": {
      "hover": {
        "lineColor": null,
        "fillColor": "white",
        "lineWidth": 4
      }
    }
  },
  "legend.enabled": false,
  "customTooltip": defaultTooltip,
  "tooltip": {
    useHTML: true,
    padding: 0,
    borderWidth: 1,
    borderColor: "#bbb",
    shared: true,
    formatter: tooltipFormatter
  },
  "chart.spacing": [10,18,10,18],
  "chart.type": "line",
  "defs": {
    "grid": {
      tagName: 'pattern',
      id: 'grid',
      patternUnits: 'userSpaceOnUse',
      width: 4,
      height: 4,
      children: [{
        tagName: 'rect', // Solid background
        x: 0,
        y: 0,
        width: 4,
        height: 4,
        fill: "#005ad4"
      }, {
        tagName: 'path',
        d: 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2'
      }]
    }
  }
})

export class Chart {
  constructor(config) {
    this.config = {}
    this.setConfig(config || getDefaultConfig())
  }

  clone() {
    return new Chart(this.config)
  }

  extend(config) {
    return this.clone().setConfig(config)
  }

  setConfig(config) {
    if (config) {
      this.config = Object.keys(config).reduce((result, key) => {
          return set(result, key, config[key])
        },
        this.config
      )
    }
    return this
  }

  getConfig() {
    return this.config
  }

  render() {
    return <Highcharts config={this.getConfig()} />
  }
}
