import { getConfig } from 'config';
import Subsidiaries from 'containers/Dashboard/components/SelectRequest';
import i18n from "utils/i18n";

export const getUserFields = () => {
  const userFields = [];
  const configFields = getConfig('tableColumns.userFields');

  const defaultFields = {
    email: {
      type: 'text',
      name: 'email'
    },
    firstName: {
      type: 'text',
      name: 'firstName',
    },
    lastName: {
      type: 'text',
      name: 'lastName',
    },
    document: {
      type: 'text',
      name: 'document',
      maxLength: getConfig('customConfig.tenantMaxLength.documentLength'),
      required: getConfig('customConfig.ecommerceStore.documentRequired'),
      onInput: (e) => {
        if (e.target.value.length) {
          e.target.value = e.target.value.replace(
            getConfig('customConfig.ecommerceStore.documentRegexValidation'),
            ''
          );
        }
      },
    },
    documentTypeId: {
      type: 'select',
      name: 'documentTypeId',
      options: [
        {
          label: 'DNI',
          value: 1,
        },
        {
          label: 'CE',
          value: 2,
        },
        {
          label: 'RUC',
          value: 3,
        },
        {
          label: 'RUS',
          value: 4,
        },
        {
          label: 'PASAPORTE',
          value: 5,
        },
      ]
    },
    phone: {
      type: 'text',
      name: 'phone',
      maxLength: getConfig('customConfig.tenantMaxLength.phoneLength'),
      onInput: (e) => {
        if (e.target.value.length) {
          e.target.value = e.target.value.replace(
            getConfig('customConfig.ecommerceStore.phoneRegexValidation'),
            ''
          );
        }
      },
    },
    roleId: {
      type: 'select',
      name: 'roleId',
      options: [
        {
          label: i18n("dashboard.userRole.supervisor"),
          value: 1,
        },
        {
          label: i18n("dashboard.userRole.seller"),
          value: 2,
        },
        !getConfig('customConfig.disabledCreateNewAdminUser') && {
          label: i18n("dashboard.userRole.administrator"),
          value: 3,
        }
      ].filter(Boolean),
      value: 'id',
      label: 'name',
    },
    subsidiaryId: {
      name: 'subsidiaryId',
      component: Subsidiaries,
    },
    statusId: {
      type: 'select',
      name: 'statusId',
      options: [
        {
          label: 'Activado',
          value: 1,
        },
        {
          label: 'Desactivado',
          value: 0,
        },
      ],
      value: 'id',
      label: 'name',
    },
    uniqueCode: {
      type: 'text',
      name: 'uniqueCode',
      maxLength: getConfig('customConfig.tenantMaxLength.documentLength'),
      onInput: (e) => {
        if (e.target.value.length) {
          e.target.value = e.target.value.replace(
            getConfig('customConfig.ecommerceStore.phoneRegexValidation'),
            ''
          );
        }
      },
    },
  };
  
  configFields.map(item => {
    return userFields.push(defaultFields[item]);
  })
  
  return userFields;
}
